import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/form/Button";
import Input from "src/components/form/Input";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import { SiteContext } from "src/context/site";
import AuthDataLayout from "src/layouts/AuthDataLayout";

const ForgotPassword = () => {
  const { loading, site } = useContext(SiteContext);

  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");

  const onSubmit = async () => {
    setDisabled(true);

    try {
      const { data: resData } = await apiRequest("post", "/portal/verify-user", {
        body: { email },
      });

      if (resData.status === 200) {
        setEmail("");
        toast.success(resData.message);
      } else {
        toast.error(resData.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisabled(false);
    }
  };

  if (loading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Preloader />
      </div>
    );
  }

  const LinkUpComponent = () => (
    <div className="mt-4">
      <div className="flex justify-center space-x-2 font-medium">
        <span>Return to</span>
        <Button
          buttonClasses={"!px-0 !h-auto !bg-transparent !rounded-none"}
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Login page"}
          buttonLabelClasses={"hover:!underline !text-highlightColor"}
        />
      </div>
    </div>
  );

  return (
    <AuthDataLayout
      title="Send a reset link"
      subTitle="Enter your sign up email and we'll take care of the rest"
      LinkUpComponent={LinkUpComponent}>
      <form
        className="flex w-full flex-col"
        id="loginform"
        action="/portal/auth-login"
        method="post">
        <div className="flex w-full flex-col gap-y-3">
          <div className="w-full">
            <Input
              inputValue={email}
              labelClasses={"font-medium"}
              label={"Email"}
              inputType={"email"}
              inputName={"email"}
              // inputPlaceholder={"Enter your email"}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-4 grid gap-y-4">
          <Button
            isDisable={disabled}
            buttonClasses={"w-full"}
            buttonType={"submit"}
            buttonLabel={"Send reset link"}
            buttonStyles={{
              borderColor: site?.highlight_color,
              backgroundColor: site?.highlight_color,
            }}
            buttonLabelClasses={"!text-sm !font-medium"}
            buttonFunction={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          />
        </div>
      </form>
    </AuthDataLayout>
  );
};

export default ForgotPassword;
