import { useLocation } from "react-router-dom";

function useUrlQueryParams() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  // Extract "group" and "page" parameters
  const group = searchParams.get("group");
  const page = searchParams.get("page");
  const redirectUrl = searchParams.get("redirectUrl");

  return { group, page, redirectUrl };
}

export default useUrlQueryParams;
