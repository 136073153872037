import { useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import TopMenuItemGroupTitle from "./TopMenuItemGroupTitle";
import TopMenuItemLink from "./TopMenuItemLink";

const TopMenuItemGroup = ({ data, group_id, page_id, searchTerm, setToggleId = () => { }, toggleId, handleClick = () => { } }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isSubmenu, setIsSubmenu] = useState(false);
  const [isSubmenuSearchOpened, setIsSubmenuSearchOpened] = useState(false);
  const [imageClassName, setImageClassName] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  // const [group, setGroup] = useState([]);
  const clickMainMenu = () => {
    if (windowWidth <= 760) {
      setIsSubmenu(!isSubmenu);
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowWidth]);

  useEffect(() => {
    if (searchTerm === "") {
      setIsSubmenuSearchOpened(false);
      return;
    }

    data.submenu.forEach((singleLink) => {
      if (singleLink.name.includes(searchTerm)) {
        setIsSubmenuSearchOpened(true);
        return;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    let visibleMenuItems = data.submenu;
    if (searchTerm) {
      let lowercaseSearchTerm = searchTerm.toLowerCase();
      visibleMenuItems = data.submenu.filter((subitem) => {
        return subitem.name.toLowerCase().includes(lowercaseSearchTerm);
      });
    }
    setMenuItems(visibleMenuItems);
  }, [data.submenu, searchTerm]);

  useEffect(() => {
    if (isSubmenu) {
      setToggleId(data?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmenu]);

  // useEffect(() => {
  //   if (toggleId && (toggleId !== data?._id || toggleId === "all")) {
  //     setIsSubmenu(false);
  //     setToggleId(null);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [toggleId, data?._id]);

  useEffect(() => {
    if (windowWidth <= 760 && (data._id === group_id || data.slug === group_id)) {
      setIsSubmenu(true);
    }
  }, [data._id, group_id, data.slug, windowWidth]);


  return (
    <div className={classNames("relative", windowWidth > 760 ? "group/item" : "")}>
      <TopMenuItemGroupTitle
        data={data}
        imageClassName={imageClassName}
        setImageClassName={setImageClassName}
        group_id={group_id}
        page_id={page_id}
        setIsSubmenu={setIsSubmenu}
        isSubmenu={isSubmenu}
        searchTerm={searchTerm}
        avoidUnderlineHover={data.submenu.length > 1}
        handleClick={handleClick}
        clickMainMenu={clickMainMenu}
        windowWidth={windowWidth}
        isSubmenuSearchOpened={isSubmenuSearchOpened}
        dropdownArrowClass={windowWidth <= 760 && (isSubmenu || isSubmenuSearchOpened) ? "" : ""} 
        // -rotate-90 
      />
      {Array.isArray(data.submenu) && data.submenu.length > 1 && (
        <div
          className={classNames(
            "relative overflow-hidden pl-3 transition-visibilityOpacity",
            "invisible mt-2 max-h-0 opacity-0 md:absolute md:top-full md:z-[1] md:max-h-[600px] md:min-w-48 md:overflow-auto md:rounded-md md:!bg-leftNavColor md:bg-white md:px-1 md:py-1 md:shadow-md space-y-1",
            windowWidth > 760 ? "group-hover/item:visible group-hover/item:opacity-100 group-hover/item:!max-h-[8000px]" : "",
            ((windowWidth < 760 && isSubmenu) || (windowWidth < 760 && isSubmenuSearchOpened)) ? "!visible !max-h-[8000px] !opacity-100" : "invisible !max-h-0 opacity-0",
          )}>
          {menuItems.map((subitem) => (
            <TopMenuItemLink
              key={subitem._id}
              data={subitem}
              group_id={group_id}
              page_id={page_id}
              searchTerm={searchTerm}
              handleClick={handleClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default TopMenuItemGroup;
