import React, { useEffect, useState, useRef } from "react";
import { classNames } from "src/helpers/classNames";
import { EyeIcon, EyeSlashIcon, ExclamationCircleIcon, ExclamationTriangleIcon, CheckCircleIcon, InformationCircleIcon } from "@heroicons/react/24/outline";

const InputGroup = ({ label, labelClasses, inputType, inputClasses, inputPlaceholder, inputValue, inputName, isInputGroup = false, inputGroupIcon, inputGroupPosition, errorType, errorText, isDisabled, onChange = () => {}, ...props }) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const passwordInput = useRef(null);
  const handleChange = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  useEffect(() => {
    passwordInput.current.type = passwordVisibility ? "text" : inputType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordVisibility]);

  return (
    <>
      {label && <div className={classNames("mb-1 text-sm font-medium text-slate-800", labelClasses)}>{label}</div>}
      <div className="relative flex">
        {isInputGroup && inputGroupPosition === "left" && (
          <div className="flex w-10 min-w-[40px] items-center justify-center rounded-md rounded-r-none border border-slate-200 bg-slate-50 text-sm text-slate-800">
            <inputGroupIcon className="h-5 w-5" />
          </div>
        )}
        <div className="w-full">
          <div className="relative flex">
            {isDisabled ? (
              <input
                type={inputType}
                ref={passwordInput}
                className={classNames(
                  "focus:border-carnation-200 focus:bg-carnation-50 h-10 w-full rounded-md border border-gray-300 bg-white px-3 text-sm text-slate-800 !outline-0 !ring-0 transition-all duration-200",
                  inputClasses,
                  isInputGroup ? (inputGroupPosition === "left" ? "!border-l-none -ml-[1px] !rounded-l-none" : "") : inputGroupPosition === "right" ? "!border-r-none -mr-[1px] !rounded-r-none" : "",
                )}
                placeholder={inputPlaceholder}
                name={inputName}
                value={inputValue}
                autoComplete={"new-" + inputType}
                disabled
              />
            ) : (
              <input
                type={inputType}
                ref={passwordInput}
                className={classNames(
                  "focus:border-carnation-200 focus:bg-carnation-50 h-10 w-full rounded-md border border-gray-300 bg-white px-3 text-sm text-slate-800 !outline-0 !ring-0 transition-all duration-200",
                  inputClasses,
                  isInputGroup ? (inputGroupPosition === "left" ? "!border-l-none -ml-[1px] !rounded-l-none" : "") : inputGroupPosition === "right" ? "!border-r-none -mr-[1px] !rounded-r-none" : "",
                )}
                placeholder={inputPlaceholder}
                name={inputName}
                value={inputValue}
                onChange={onChange}
                autoComplete={"new-" + inputType}
              />
            )}
            {inputType === "password" && (
              <button
                type="button"
                className="absolute right-0 top-0 h-10 w-10 bg-transparent text-slate-500"
                onClick={handleChange}>
                {!passwordVisibility ? <EyeSlashIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
              </button>
            )}
          </div>
        </div>
        {isInputGroup && inputGroupPosition === "right" && (
          <div className="flex w-10 min-w-[40px] items-center justify-center rounded-md rounded-l-none border border-slate-200 bg-slate-50 text-sm text-slate-500">
            <inputGroupIcon className="h-5 w-5" />
          </div>
        )}
      </div>
      {errorType && errorType === "danger" && (
        <div className="mt-1 text-xs text-red-600">
          <ExclamationTriangleIcon className="mr-1 h-5 w-5" />
          {errorText}
        </div>
      )}
      {errorType && errorType === "warning" && (
        <div className="mt-1 text-xs text-amber-600">
          <ExclamationCircleIcon className="mr-1 h-5 w-5" />
          {errorText}
        </div>
      )}
      {errorType && errorType === "success" && (
        <div className="mt-1 text-xs text-green-600">
          <CheckCircleIcon className="mr-1 h-5 w-5" />
          {errorText}
        </div>
      )}
      {errorType && errorType === "info" && (
        <div className="mt-1 text-xs text-sky-600">
          <InformationCircleIcon className="mr-1 h-5 w-5" />
          {errorText}
        </div>
      )}
    </>
  );
};

export default InputGroup;
