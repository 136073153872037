import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "src/helpers/classNames";

const Button = ({ buttonClasses, buttonStyles = {}, buttonType, buttonIconPosition, buttonIconClass, buttonLabel, buttonLabelClasses, buttonFunction = () => {}, buttonHasLink, buttonLink, isDisable = false, ...props }) => {
  return (
    <>
      {buttonHasLink ? (
        <Link
          to={buttonLink}
          className={classNames("relative flex h-9 items-center justify-center gap-2 overflow-hidden rounded-md bg-rose-500 px-3 py-0 text-lg text-white transition-all duration-200", buttonClasses, isDisable ? "pointer-events-none opacity-75" : "")}>
          {buttonIconPosition === "left" && <props.buttonIcon className={classNames("h-5 w-5", buttonIconClass)} />}
          {buttonLabel && <span className={classNames("text-base transition-all duration-200", buttonLabelClasses)}>{buttonLabel}</span>}
          {buttonIconPosition === "right" && <props.buttonIcon className={classNames("h-5 w-5", buttonIconClass)} />}
        </Link>
      ) : (
        <button
          type={buttonType}
          className={classNames("relative flex h-9 items-center justify-center gap-2 overflow-hidden rounded-md bg-rose-500 px-3 py-0 text-lg text-white transition-all duration-200", buttonClasses, isDisable ? "pointer-events-none opacity-75" : "")}
          style={buttonStyles}
          onClick={buttonFunction}
          disabled={isDisable}>
          {buttonIconPosition === "left" && <props.buttonIcon className={classNames("h-5 w-5", buttonIconClass)} />}
          {buttonLabel && <span className={classNames("text-base transition-all duration-200", buttonLabelClasses)}>{buttonLabel}</span>}
          {buttonIconPosition === "right" && <props.buttonIcon className={classNames("h-5 w-5", buttonIconClass)} />}
        </button>
      )}
    </>
  );
};

export default Button;
