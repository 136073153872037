import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import { logoutRedirect } from "src/async/fetch";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";

const SSOLogin = () => {
  const navigate = useNavigate();

  const clickWorkspace = async () => {
    try {
      const { data: resData } = await apiRequest("GET", `/portal/users/workspaces/page`);

      if (resData.status === 200) {
        navigate(resData.link);
      } else {
        navigate("/permission-denied");
      }
    } catch (err) {
      navigate("/permission-denied");
    }
  };

  const loadUser = async () => {
    const accessToken = new URLSearchParams(window.location.search).get("access_token");
    const refreshToken = new URLSearchParams(window.location.search).get("refresh_token");

    if (!accessToken) logoutRedirect();

    try {
      const { data: resData } = await apiRequest("post", "/portal/users/self/details", {
        body: {
          token: accessToken,
          access_token: accessToken,
        },
      });

      if (resData.status !== 200) {
        logoutRedirect();
      }

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      await clickWorkspace();
    } catch (error) {
      if (error.message !== "Failed to fetch" && error.message !== "The user aborted a request.") {
        logoutRedirect();
      }
    }
  };

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  return (
    <div className="flex h-full items-center justify-center">
      <Preloader />
    </div>
  );
};

export default SSOLogin;
