export const urlBuilder = (url, replacementObject) => {
  let modifiedURL = url;

  for (const key in replacementObject) {
    if (replacementObject.hasOwnProperty(key)) {
      modifiedURL = modifiedURL.replace(`:${key}`, replacementObject[key]);
    }
  }

  return modifiedURL;
};
