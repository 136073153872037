import { useContext } from "react";
import { WorkspaceContext } from "src/context/workspace";
import { classNames } from "src/helpers/classNames";
import ExternalLinkEmbed from "../ExternalLinkEmbed";

export default function LinkEmbed({ content }) {
  const { menuType } = useContext(WorkspaceContext);

  return (
    <div
      className={classNames(
        "relative h-full w-full",
        menuType === "horizontal" || menuType === "vertical" ? "" : "", // for horizontal h-screen minus the top menu bar (x2)
      )}>
      <div className="h-full w-full overflow-auto">
        <ExternalLinkEmbed
          href={content?.page_link}
          target={content?.page_link_newtab}
        />
      </div>
    </div>
  );
}
