import React, { useEffect } from "react";
import CustomHtmlEmbed from "src/components/embedTypes/basic/CustomHtmlEmbed";
import LinkEmbed from "src/components/embedTypes/basic/LinkEmbed";

export default function BasicRouter({ content, config, menuType, workspace, site, setError, setLoading }) {
  useEffect(() => {
    if (!content?.config?.provider) {
      setError("Provider type does not exist.");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.config?.provider]);

  return (
    <>
      {config?.type === "CUSTOM_HTML" && <CustomHtmlEmbed content={content} />}
      {config?.type === "EXTERNAL_LINK" && <LinkEmbed content={content} />}
    </>
  );
}
