const ErrorPage = () => {
  return (
    <div className="flex h-full w-full items-center justify-center bg-backgroundColor py-5">
      <div className="text-center lg:mb-40">
        <h1 className="text-9xl font-bold text-gray-800">404</h1>
        <p className="mb-4 text-2xl font-semibold text-gray-600">Page Not Found</p>
        <p className="text-gray-500">Sorry, the page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default ErrorPage;
