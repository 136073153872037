import React from "react";
import { Navigate, useParams } from "react-router-dom";

const RedirectToEmbed = () => {
  const { workspace_id, page_id, group_id } = useParams();

  if (group_id) {
    return (
      <Navigate
        to={`/workspace/${workspace_id}/embed?group=${group_id}&page=${page_id}`}
        replace
      />
    );
  } else {
    return (
      <Navigate
        to={`/workspace/${workspace_id}/embed?page=${page_id}`}
        replace
      />
    );
  }
};

export default RedirectToEmbed;
