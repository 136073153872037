import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export default function Content({ pageErrorTypes, pageError }) {
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-col items-center justify-center px-2 py-12 sm:px-6 lg:px-8">
      <div className="flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-md">
        <button
          onClick={() => navigate("/")}
          className="group flex cursor-pointer items-center gap-x-2 rounded-md bg-gray-50 px-3 py-2 shadow-md transition-all duration-150 hover:bg-gray-100 hover:shadow-lg">
          <p className="flex h-full items-center pb-1 text-2xl text-gray-400 transition-all duration-150 group-hover:text-gray-500">{pageErrorTypes[pageError].title}</p>
          <ArrowPathIcon className="h-6 w-6 stroke-gray-400 transition-all duration-150 group-hover:stroke-gray-500" />
        </button>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 sm:text-3xl">{pageErrorTypes[pageError].subtitle}</h2>
        <div className="text-regular mt-1 text-center text-gray-600">{pageErrorTypes[pageError].secondary}</div>
      </div>
    </div>
  );
}
