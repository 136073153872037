import { XMarkIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "src/assets/images/logo.png";
import Image from "src/components/elements/Image";
import Button from "src/components/form/Button";
import Search from "src/components/form/Search";
import TopMenuItemGroup from "src/components/navigation/topNavigation/TopMenuItemGroup";
import TopMenuItemLink from "src/components/navigation/topNavigation/TopMenuItemLink";
import { outerPages } from "src/config/host";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import { searchMenu } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";

const TopNavigationMenu = ({ isMenu, setIsMenu, menus = [], simulatedMenus = [], setSimulatedMenus = () => {}, keyword = "", setKeyword = () => {} }) => {
  const { workspace_id } = useParams();
  const { group: group_id, page: page_id } = useUrlQueryParams();
  const { site } = useContext(SiteContext);
  const { details: workspaceDetails } = useContext(WorkspaceContext);

  const [isLogoVisible, setIsLogoVisible] = useState(false);
  const [toggleId, setToggleId] = useState(null);

  useEffect(() => {
    if (workspace_id && workspaceDetails?._id) {
      setIsLogoVisible(true);
    }

    return () => {
      setIsLogoVisible(false);
    };
  }, [workspace_id, workspaceDetails?._id]);

  const isOuterPage = outerPages.includes(window.location.pathname);

  const searchOnMenus = (keyword = "") => {
    if (!keyword) {
      setSimulatedMenus(menus);
    } else {
      setSimulatedMenus(searchMenu(menus, keyword));
    }
  };

  const groupMenus = menus.filter((menu) => menu.type === "group");
  const individualMenus = menus.filter((menu) => menu.type !== "group");

  const handleClick = (group_id) => {
    setToggleId(group_id ? group_id : "all");
  };

  return (
    <>
      <div
        className={classNames("absolute left-0 top-0 z-[39] block h-full w-screen bg-black transition-all duration-200 md:hidden", isMenu ? "visible opacity-80" : "invisible opacity-0")}
        onClick={() => setIsMenu(!isMenu)}></div>
      <div
        className={classNames("fixed left-0 top-0 z-[40] flex h-screen w-80 flex-col border-b border-gray-200  !bg-leftNavColor bg-white transition-all duration-200 md:sticky md:top-16 md:z-10 md:h-auto md:w-full", !isMenu || isOuterPage ? "-translate-x-96" : "translate-x-0")}>
        <div className={classNames("relative flex h-16 flex-shrink-0 items-center !bg-headerColor pl-4 md:hidden md:justify-center md:pl-0")}>
          <Button
            buttonIcon={XMarkIcon}
            buttonIconPosition={"right"}
            buttonClasses={"!bg-transparent !absolute top-2 left-full !ml-2 !w-10 !h-10 !px-0 !rounded-full !border-2 !border-white"}
            buttonIconClass={"!w-6 !h-6 !stroke-2"}
            buttonFunction={() => setIsMenu(!isMenu)}
          />
          <div className="h-10">
            <Link
              to={"/"}
              className="block h-full w-full">
              {!workspace_id || workspaceDetails?.use_global_settings ? (
                site?._id && isLogoVisible ? (
                  <Image
                    src={site?.image_logo ? `${site.image_logo}?${new Date().getTime()}` : Logo}
                    alt={"Logo"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain object-left"}
                    hideImageOnError
                  />
                ) : (
                  <></>
                )
              ) : workspaceDetails?._id && isLogoVisible ? (
                <Image
                  src={workspaceDetails?.image_logo ? `${workspaceDetails?.image_logo}?${new Date().getTime()}` : Logo}
                  alt={"Logo"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-contain object-left"}
                  hideImageOnError
                />
              ) : (
                <></>
              )}
            </Link>
          </div>
        </div>
        {groupMenus.reduce((total, group) => (group?.permissions?.length ? total + group?.permissions?.length : total), individualMenus.length) > 15 && (
          <div className={classNames("block flex-shrink-0 bg-white p-2 px-2 py-3 md:hidden md:px-0 md:py-0")}>
            <Search
              value={keyword}
              placeholder="Search"
              onChange={(e) => {
                setKeyword(e.target.value);
                searchOnMenus(e.target.value);
              }}
            />
          </div>
        )}
        <div className={classNames("h-full w-full flex-shrink items-center space-y-2 overflow-y-scroll py-2 md:flex md:space-y-0 md:overflow-y-visible md:px-2")}>
          {" "}
          {/* sm:py-0 md:h-[3.5rem] */}
          <div className={classNames("space-y-2 px-2 md:flex md:w-full md:flex-wrap md:gap-1 md:space-y-0")}>
            {simulatedMenus.map((item) =>
              item.type === "group" ? (
                <TopMenuItemGroup
                  key={item._id}
                  data={item}
                  group_id={group_id}
                  page_id={page_id}
                  searchTerm={keyword ? keyword.toLowerCase() : ""}
                  toggleId={toggleId}
                  setToggleId={setToggleId}
                  handleClick={handleClick}
                />
              ) : (
                <TopMenuItemLink
                  key={item._id}
                  data={item}
                  group_id={group_id}
                  page_id={page_id}
                  searchTerm={keyword ? keyword.toLowerCase() : ""}
                  handleClick={handleClick}
                />
              ),
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNavigationMenu;
