import { Menu, Transition } from "@headlessui/react";
import { bottom } from "@popperjs/core";
import { useContext, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { Link } from "react-router-dom";
import S3Image from "src/components/shared/S3Image";
import { AuthContext } from "src/context/auth";
import { classNames } from "src/helpers/classNames";

const UserDropdown = ({ data, xPlacement, ...props }) => {
  const { user } = useContext(AuthContext);
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button
              ref={setTargetElement}
              className="flex items-center">
              <div className="relative border-transparent rounded-md w-full flex items-center justify-between gap-x-3 transition-all duration-200">
                <div className="hidden font-medium sm:block text-sm text-headerTextColor">{user?.name || ""}</div>
                <div className="w-8 h-8 overflow-hidden rounded-full flex-shrink-0">
                  <S3Image
                    src={user?.image}
                    alt={user?.name || ""}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-cover"}
                    userNoImage={user?._id && !user.image ? true : false}
                  />
                </div>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave="transition duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}>
              <Menu.Items className="min-w-48 min-w-[168px] origin-top-right bg-white border divide-y divide-slate-100 rounded-md shadow-lg ring-0 border-gray-200">
                <div className="py-1 max-h-80 overflow-auto ">
                  {data.dropdownData
                    ?.filter((item) => !item.hidden)
                    .map((item) => (
                      <Menu.Item key={item._id}>
                        {item.link ? (
                          <Link
                            to={item.link}
                            className={classNames("w-full flex items-center py-2.5 px-3 gap-3 text-gray-700 transition-all duration-200", item.standout ? "text-rose-500 hover:bg-rose-100 hover:text-rose-500" : "text-gray-700 hover:text-highlightColor")}>
                            <div className="w-5 h-5 flex items-center justify-center text-base">
                              <item.icon className="w-4 h-4 stroke-1.5" />
                            </div>
                            <div className="text-sm">{item.label}</div>
                          </Link>
                        ) : (
                          <button
                            type="button"
                            className={classNames("w-full flex items-center py-2.5 px-3 gap-3 text-gray-700 transition-all duration-200", item.standout ? "text-rose-500 hover:bg-rose-100 hover:text-rose-500" : "text-gray-700 hover:text-highlightColor")}
                            onClick={item.onclick || (() => {})}>
                            <div className="w-5 h-5 flex items-center justify-center text-base">
                              <item.icon className="w-4 h-4 stroke-1.5" />
                            </div>
                            <div className="text-sm">{item.label}</div>
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default UserDropdown;
