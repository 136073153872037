import { useContext, useEffect } from "react";
import { Navigate, createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import { logoutRedirect } from "src/async/fetch";
import { AuthContext } from "src/context/auth";
import Dashboardlayout from "src/layouts/DashboardLayout";

const ProtectedRoute = () => {
  const { user, setUser } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const params = { redirectUrl: `${location.pathname}${location.search}` };
  const queryString = `?${createSearchParams(params)}`;

  useEffect(() => {
    const loadUser = async () => {
      if (user?._id) return;

      if (localStorage.getItem("accessToken")) {
        try {
          const { data: resData } = await apiRequest("post", "/portal/users/self/details", {
            body: {
              token: localStorage.getItem("accessToken"),
              access_token: localStorage.getItem("accessToken"),
            },
          });

          if (resData.status !== 200) {
            logoutRedirect();
          }

          setUser(resData.data);
        } catch (error) {
          if (error.message !== "Failed to fetch" && error.message !== "The user aborted a request.") {
            logoutRedirect();
          }
        }
      } else {
        navigate({
          pathname: "/",
          search: queryString,
        });
      }
    };

    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  if (!localStorage.getItem("accessToken")) {
    return <Navigate to={`/${queryString}`} />;
  }

  if (window.location.pathname.includes("/embeded/") || window.location.pathname.includes("/embed/items/")) {
    return <Dashboardlayout nested />;
  }

  return <Dashboardlayout />;
};

export default ProtectedRoute;
