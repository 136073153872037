export function setBrowserStyleProperties(document, settings) {
  if (document.settings_id === settings._id || !settings) return;
  document.settings_id = settings._id;
  document.documentElement.style.setProperty("--highlightColor", settings.highlight_color, "important");
  document.documentElement.style.setProperty("--headerColor", settings.top_bar_color, "important");
  document.documentElement.style.setProperty("--headerTextColor", settings.top_bar_text_color, "important");
  document.documentElement.style.setProperty("--leftNavColor", settings.side_bar_color, "important");
  document.documentElement.style.setProperty("--leftNavActiveColor", settings.side_bar_shade_color, "important");
  document.documentElement.style.setProperty("--leftNavTextColor", settings.side_bar_text_color, "important");
  document.documentElement.style.setProperty("--leftNavActiveTextColor", settings.left_nav_active_text_color, "important");
  document.documentElement.style.setProperty("--backgroundColor", settings.background_color || "#fff", "important");
  document.title = settings.site_name || settings.name || "";

  if (settings.image_favicon) {
    document.querySelector("link[rel*='icon']").href = `${settings.image_favicon}?${Date.now()}`;
  } else {
    document.querySelector("link[rel*='icon']").href = "./assets/images/generic_world_favicon.png";
  }
}
